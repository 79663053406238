<template>
    <div class="goods-info-content">
        <div class="theory-list-header" style="padding-top: 20px">
            状态
            <el-select v-model="stateValue" style="width: 200px;margin-left: 10px" placeholder="请选择" @change="selectStates">
                <el-option
                        v-for="item in stateList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
        </div>
        <el-table :data="studentSubmitList" class="customTable" style="width: 100%; flex: 1; margin-top: 20px" height="1%">
<!--            <el-table-column prop="username" label="学生名字"></el-table-column>
            <el-table-column prop="exam_name" label="考试名称"></el-table-column>-->
            <el-table-column label="考试模块" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.exam_module}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态"></el-table-column>
            <el-table-column prop="exam_time" label="考试时间"></el-table-column>
            <el-table-column :label="`评分（最高分数为${total_score}分）`" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.status === '未提交'">该学生未提交暂不能评分</span>
                    <div v-else>
                        <el-input-number
                                v-model="scope.row.score"
                                :min="0"
                                :max="total_score"
                                :controls="false"
                                :precision="0"
                                style="width: 88px;">
                        </el-input-number>
                        <span @click="submitScore(scope.row)" class="submit-score">{{scope.row.status === '已批改' ? '重新提交' : '确定'}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div class="link-box">
                        <el-link type="success" :underline="false"
                                 @click="viewGoodsImgs(scope.row)"
                                 v-if="scope.row.status !== '未提交'"
                                 class="view-detail">
                            {{scope.row.exam_module === '商品视频设计' ? '下载文件' : '查看详情'}}
                        </el-link>
                        <el-link type="success" :underline="false" @click="viewGrading(scope.row)" class="view-detail">评分标准</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                       :current-page="studentSubmitPages.currentPageNum"
                       :page-size="studentSubmitPages.eachPageNum"
                       :total="studentSubmitPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="studentSubmitCurrentChange">
        </el-pagination>
        <el-dialog title="商品主图" :visible.sync="goodsImgsDialog" width="764px" @close="handleCloseGoodsImgsDialog" custom-class="green-dialog goods-img-dialog">
            <div class="upload-file-content">
                <div class="big-img">
                    <img :src="bigImg" alt="">
                </div>
            </div>
        </el-dialog>
        <el-dialog title="评分标准"
                   :visible.sync="gradingDialog" width="830px" @close="handleCloseGradingDialog" custom-class="green-dialog goods-score-dialog">
            <div class="upload-file-content">
                <el-table :data="gradingList" class="customTable goods-text-table" style="width: 100%; flex: 1;" height="1%"
                          :header-cell-style="{fontWeight: 'normal', height: '40px', color: '#333', background: '#F2F3FA',fontSize: '14px'}"
                          :cell-style="{fontSize: '14px',color: '#333333',height: '40px'}">
                    <el-table-column prop="index" label="序号" align="center"></el-table-column>
                    <el-table-column prop="content" label="考核内容" align="center"></el-table-column>
                    <el-table-column prop="require" label="考核要点" align="center"></el-table-column>
                    <el-table-column prop="standard" label="评分标准" align="center"></el-table-column>
                    <el-table-column prop="score" label="配分" align="center"></el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {teacheropStoreVisualDesignList, teacheropStoreVisualDesignScore} from '@/utils/apis'
    export default {
        name: "Index",
        data() {
            return {
                //学生提交列表
                studentSubmitList: [],
                //商品主图弹窗
                goodsImgsDialog: false,
                selectIndex: 0,
                //大图
                bigImg: '',
                //评分标准列表
                gradingList: [],
                //评分标准弹窗
                gradingDialog: false,
                //分页
                studentSubmitPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //状态列表
                stateList:[
                    {
                        value:1,
                        label:'未批改'
                    },
                    {
                        value:2,
                        label:'已批改'
                    },
                    {
                        value:3,
                        label:'未提交'
                    },
                ],
                stateValue: 1,
                //分数
                scoreInput: '',
                //评分（最高分数）
                total_score: ''
            }
        },
        created() {
            this.getStudentSubmitList();
        },
        methods: {
            //切换状态
            selectStates(){
                this.studentSubmitPages.currentPageNum = 1;
                this.scoreInput = '';
                this.getStudentSubmitList();
            },
            //获取网店视觉设计列表
            getStudentSubmitList() {
                let param = {
                    exam_id: this.$route.query.examId,
                    status: this.stateValue
                }
                teacheropStoreVisualDesignList(param).then((res) => {
                    this.studentSubmitList = res.data.list;
                    if (res.data.list.length > 0) {
                        this.total_score = res.data.total_score;
                    } else {
                        this.total_score = 0;
                    }

                    this.gradingList = res.data.scoring_criteria;
                    this.studentSubmitPages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                })
            },
            //查看详情
            viewGoodsImgs(row) {
                if (row.exam_module === '商品视频设计') {
                    window.location.href = row.file_path;
                } else {
                    this.goodsImgsDialog = true;
                    this.bigImg = row.file_path;
                }
            },
            //关闭商品主图弹窗
            handleCloseGoodsImgsDialog() {
                this.goodsImgsDialog = false;
                this.selectIndex = 0;
            },
            //查看评分标准
            viewGrading() {
                this.gradingDialog = true;
            },
            //关闭评分标准弹窗
            handleCloseGradingDialog() {
                this.gradingDialog = false;
            },
            //分页
            studentSubmitCurrentChange(val) {
                this.studentSubmitPages.currentPageNum = val;
                this.getStudentSubmitList();
            },
            //提交分数
            submitScore(row) {
                let type = '';
                if (row.exam_module === '网店整体风格设计') {
                    type = '1'
                }
                if (row.exam_module === '页面设计') {
                    type = '2'
                }
                if (row.exam_module === '商品视频设计') {
                    type = '3'
                }
                let param = {
                    exam_id: this.$route.query.examId,
                    stu_id: row.id,
                    score: row.score,
                    type: type
                }
                teacheropStoreVisualDesignScore(param).then((res) => {
                    this.$message.success('提交成功');
                    this.getStudentSubmitList();
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-info-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .link-box {
            .view-detail {
                ::v-deep .el-link--inner {
                    color: #1AB163;
                }
            }
        }
        .goods-img-dialog {
            .upload-file-content {
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: 445px;
                .big-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 444px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
    }
    .theory-list-header{
        display: flex;
        align-items: center;
        span{
            line-height: 1;
        }
    }
    .submit-score {
        margin-left: 10px;
        color: #FF9450;
        font-size: 16px;
        cursor: pointer;
    }
</style>